import DifficultyLevelSettings from '../types/DifficultyLevelSettings';
import actions from '../store/actions';
import storeService from './storeService';
import RoundState from '../types/RoundState';
import Rule from '../types/Rule';
import GameMode from '../types/GameMode';
import Condition from '../types/Condition';
import Element from '../types/Element';
import integrationService from './integrationService';
import graylogService from './graylogServices';
import dispatcherService from './dispatcherService';

class GameService {
    // call integrationService to report app start
    // starts new round using settings of difficulty level 0
    newGame = async () => {
        const integrationParams = storeService.getStore().getState().general.integrationParams;

        let sendStartRequest = await integrationService.sendStart(integrationParams);
        if (sendStartRequest.status !== 200) {
            await graylogService.sendMessage({
                short_message: `[${sendStartRequest.status}]  Request error: ${sendStartRequest.url}`,
                full_message: `[${sendStartRequest.status}]  Request error: ${sendStartRequest.url}`,
                _body: integrationService.buildPleBody(integrationParams),
                _body_result: await sendStartRequest.text(),
            });

            dispatcherService.setMode('warning');
            dispatcherService.setWarningType('start');
        } else {
            let settings = storeService.getStore().getState().general.settings;

            this.newRound(settings[0]);
            storeService.getStore().dispatch(actions.general.startGame());
        }
    };

    generateBoard(rule: Rule, levelSettings: DifficultyLevelSettings): Element[][] {
        const sequences: Element[][] = [];
        for (let i = 0; i < levelSettings.roundSettings.sequencesAtStart; i++) {
            sequences.push(
                this.generateRandomSequence(rule, levelSettings.roundSettings.sequenceLength)
            );
        }

        const conditions: Condition[] = ['big', 'small', 'round', 'square', 'white', 'black'];
        const mode = storeService.getStore().getState().general.currentRoundState.settings.mode;

        const doesBoardHasIfXThenXRule = conditions.every(
            condition =>
                !this.checkUserAnswer(
                    {
                        type: mode,
                        conditionIf: condition,
                        conditionAndOr: mode !== 'single' ? condition : null,
                        conditionThen: condition,
                    },
                    sequences
                )
        );

        return doesBoardHasIfXThenXRule ? sequences : this.generateBoard(rule, levelSettings);
    }

    // sets store currentRound based on settings provided (also clears stats)
    newRound(levelSettings: DifficultyLevelSettings): void {
        const rule = this.generateRandomRule(levelSettings.roundSettings.mode);
        const sequences: Element[][] = this.generateBoard(rule, levelSettings);

        const currentRoundState: RoundState = {
            settings: levelSettings.roundSettings,
            attemptsLeft: levelSettings.roundSettings.attemptsCount,
            sequenceCount: levelSettings.roundSettings.sequencesAtStart,
            rule,
            sequences,
        };
        storeService.getStore().dispatch(actions.general.setCurrentRoundState(currentRoundState));

        storeService.getStore().dispatch(actions.general.clearStats());
    }

    generateRandomRule = (mode: GameMode): Rule => {
        const conditionIf = this.generateRandomCondition();
        const conditionThen = this.generateRandomCondition(conditionIf);
        const conditionAndOr =
            mode === 'single' ? null : this.generateRandomCondition(conditionIf, conditionThen);
        return { type: mode, conditionIf, conditionThen, conditionAndOr };
    };

    // generates random condition that isn't included in condidionsAlreadyUsed
    // could be implemented easier and shorter bot for some weird reason it's quite complicated
    generateRandomCondition = (...conditionsAlreadyUsed: Condition[]): Condition => {
        const conditions: Condition[][] = [
            ['big', 'small'],
            ['round', 'square'],
            ['white', 'black'],
        ];
        let conditionType: Condition[];
        let condition: Condition;

        if (conditionsAlreadyUsed.length < 2) {
            do {
                conditionType = conditions[Math.floor(Math.random() * conditions.length)];
                condition = conditionType[Math.floor(Math.random() * conditionType.length)];
            } while (conditionsAlreadyUsed.includes(condition));
        } else {
            do {
                conditionType = conditions[Math.floor(Math.random() * conditions.length)];
                condition = conditionType[Math.floor(Math.random() * conditionType.length)];
            } while (
                conditionsAlreadyUsed.includes(condition) ||
                conditionType.includes(conditionsAlreadyUsed[0])
            );
        }

        return condition;
    };

    generateRandomSequence = (rule: Rule, length: number): Element[] => {
        const ret: Element[] = [];
        ret[0] = this.generateRandomElement();
        for (let i = 1; i < length; i++) {
            let e: Element;

            // generate random elements until requirements met
            do {
                e = this.generateRandomElement();
            } while (!this.checkRule(ret[i - 1], e, rule));

            ret.push(e);
        }

        return ret;
    };

    generateRandomElement = (): Element => ({
        size: ['big', 'small'][Math.floor(Math.random() * 2)] as 'big' | 'small',
        color: ['black', 'white'][Math.floor(Math.random() * 2)] as 'black' | 'white',
        shape: ['square', 'round'][Math.floor(Math.random() * 2)] as 'square' | 'round',
    });

    // checks if element matches given condition
    checkElementCondition = (e: Element, c: Condition): boolean => {
        if (c === 'big' || c === 'small') {
            return e.size === c;
        }
        if (c === 'black' || c === 'white') {
            return e.color === c;
        }
        if (c === 'round' || c === 'square') {
            return e.shape === c;
        }
        return false; // actually this line won't run
    };

    // checks if element sequences satisfy the rule provided
    checkSequence = (rule: Rule, s: Element[]): boolean => {
        for (let i = 1; i < s.length; i++) {
            const e1: Element = s[i - 1];
            const e2: Element = s[i];
            if (!this.checkRule(e1, e2, rule)) {
                return false; // sequence doesn't match rule
            }
        }
        return true;
    };

    checkUserAnswer = (rule: Rule, sequences: Element[][]): boolean => {
        for (const sequence of sequences) {
            if (!this.checkSequence(rule, sequence)) {
                return false;
            } // sequence doesn't match user's rule
        }
        return true;
    };

    // checks if sequence of two elements meets the rule given
    checkRule = (e1: Element, e2: Element, r: Rule): boolean => {
        switch (r.type) {
            case 'single':
                // if first element doesn't match condition, second one could be anything
                if (!this.checkElementCondition(e1, r.conditionIf)) {
                    return true;
                }
                return this.checkElementCondition(e2, r.conditionThen); // check
            case 'and':
                // if first element doesn't match both conditions, second one could be anything
                if (
                    !this.checkElementCondition(e1, r.conditionIf) ||
                    !this.checkElementCondition(e1, r.conditionAndOr as Condition)
                ) {
                    return true;
                }
                return this.checkElementCondition(e2, r.conditionThen); // check
            case 'or':
                if (
                    this.checkElementCondition(e1, r.conditionIf) ||
                    this.checkElementCondition(e1, r.conditionAndOr as Condition)
                ) {
                    return this.checkElementCondition(e2, r.conditionThen);
                } else {
                    return true;
                } // if first element doesn't match any of conditions, second one could be anything

            default:
                return false;
        }
    };
}

const gameService = new GameService();
export default gameService;
