import React, { Component } from 'react';
import Element from '../types/Element';

interface IGameElementProps {
    element: Element;
}

class SequenceElement extends Component<IGameElementProps> {
    render() {
        const e = this.props.element;
        if (e.size === 'small' && e.shape === 'round' && e.color === 'black')
            return <div className="shape shape_small shape_round shape_black"></div>;
        if (e.size === 'small' && e.shape === 'round' && e.color === 'white')
            return <div className="shape shape_small shape_round shape_white"></div>;
        if (e.size === 'small' && e.shape === 'square' && e.color === 'black')
            return <div className="shape shape_small shape_square shape_black"></div>;
        if (e.size === 'small' && e.shape === 'square' && e.color === 'white')
            return <div className="shape shape_small shape_square shape_white"></div>;
        if (e.size === 'big' && e.shape === 'round' && e.color === 'black')
            return <div className="shape shape_big shape_round shape_black"></div>;
        if (e.size === 'big' && e.shape === 'round' && e.color === 'white')
            return <div className="shape shape_big shape_round shape_white"></div>;
        if (e.size === 'big' && e.shape === 'square' && e.color === 'black')
            return <div className="shape shape_big shape_square shape_black"></div>;
        if (e.size === 'big' && e.shape === 'square' && e.color === 'white')
            return <div className="shape shape_big shape_square shape_white"></div>;
        return null;
    }
}

export default SequenceElement;
