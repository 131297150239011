import React from 'react';
import { useSelector } from 'react-redux';
import AppState from '../../types/AppState';
import { Result } from 'antd';

function WarningMessage() {
    const { warningType } = useSelector((state: AppState) => ({
        warningType: state.general.warningType,
    }));

    let message = '';
    switch (warningType) {
        case 'start':
            message =
                'Извините, произошла ошибка подключения диагностической активности. Пожалуйста, обратитесь в поддержку для исправления ситуации.';
            break;
        case 'result':
            message =
                'Извините, ваши данные не удалось загрузить. Вы можете начать сначала или обратиться в поддержку.';
            break;
    }

    return (
        <>
            <Result status="warning" title={message} />
        </>
    );
}

export default WarningMessage;
