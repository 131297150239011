import { StatisticLevelHelper } from '../helpers/statisticLevelHelper';
import DifficultyLevelSettings from '../types/DifficultyLevelSettings';
import LevelStatictics from '../types/statistics/LevelStatistics';
import SessionStatistics from '../types/statistics/SessionStatistics';

export class StatisticService {
    public startSessionTime: Date;
    public currentLevelHelper: StatisticLevelHelper;
    private levelHelpers: StatisticLevelHelper[];
    private totalScore: number = 0;

    constructor(levelOptions: DifficultyLevelSettings) {
        this.currentLevelHelper = new StatisticLevelHelper(levelOptions);
        this.levelHelpers = new Array<StatisticLevelHelper>();
        this.startSessionTime = new Date();
    }

    public wrongAnswer() {
        this.currentLevelHelper.wrongAnswer();
        // console.log('wrong answer', this);
    }

    public rightAnswer() {
        this.currentLevelHelper.rightAnswer();
        // console.log('right answer', this);
    }

    public passRound(isPreviousRoundDone: boolean) {
        this.currentLevelHelper.nextRound(isPreviousRoundDone);
    }

    public passLevel(isPreviousRoundDone: boolean) {
        this.currentLevelHelper.doneLevel(isPreviousRoundDone);
        this.currentLevelHelper.setLevelScore();
        this.levelHelpers.push(this.currentLevelHelper);
        // console.log('pass level', this);
    }

    public newLevel(nextOptions: DifficultyLevelSettings) {
        this.currentLevelHelper = new StatisticLevelHelper(nextOptions);
    }

    public abort() {
        this.currentLevelHelper.abort();
        this.levelHelpers.push(this.currentLevelHelper);
    }

    public setTotalScore(totalScore: number) {
        this.totalScore = totalScore;
    }

    public getSessionStatistic(): SessionStatistics {
        const totalStatistics = new Array<LevelStatictics>();
        this.levelHelpers.forEach((levelHeloer) =>
            totalStatistics.push(levelHeloer.getLevelStatistic())
        );

        let totalScore = 0;
        totalStatistics.forEach((levelStatistics) => {
            totalScore += levelStatistics.score;
        });
        this.setTotalScore(totalScore);

        return {
            _rawData: { levels: totalStatistics },
            score1: totalStatistics[0].score,
            score2: totalStatistics[1] ? totalStatistics[1].score : null,
            scoreAll: this.totalScore,
            timeAll:
                totalStatistics[0].levelPassTime +
                (totalStatistics[1] ? totalStatistics[1].levelPassTime : 0),
            time11: totalStatistics[0].rounds[0].passTime,
            time12: totalStatistics[0].rounds[1].passTime,
            time13: totalStatistics[0].rounds[2].passTime,
            time21: totalStatistics[1] ? totalStatistics[1].rounds[0].passTime : null,
            time22: totalStatistics[1] ? totalStatistics[1].rounds[1].passTime : null,
        };
    }
}
