import actions from '../actions/types';
import { updateObject } from './utility';
import StoreAction from '../../types/StoreAction';
import GeneralState from '../../types/GeneralState';

const initialState: GeneralState = {
    gameStarted: false,
    settings: [
        // TODO move to some safe place hardcoded game settings
        {
            roundSettings: {
                mode: 'single',
                sequenceLength: 5,
                sequencesAtStart: 3,
                attemptsCount: 3,
            },
            roundsCount: 3,
        },
        {
            roundSettings: {
                mode: 'and',
                sequenceLength: 6,
                sequencesAtStart: 3,
                attemptsCount: 3,
            },
            roundsCount: 2,
        },
    ],
    currentRoundState: {
        // TODO review
        // maybe not the best solution, but I try to avoid currentRound
        // having type "RoundInfo | null" so that's initial state that
        // will never be used
        sequenceCount: 1,
        attemptsLeft: 1,
        settings: {
            mode: 'single',
            sequenceLength: 1,
            sequencesAtStart: 1,
            attemptsCount: 1,
        },
        rule: {
            type: 'single',
            conditionIf: 'big',
            conditionAndOr: null,
            conditionThen: 'black',
        },
        sequences: [],
    },
    currentRoundIndex: 0,
    currentDifficultyLevelIndex: 0,
    stats: null,
    mode: 'game',
    warningType: 'none',
    integrationParams: {
        token: '',
        unti_id: '',
        activity_uuid: '',
        darkMode: false,
    },
};

export default (state: GeneralState = initialState, action: StoreAction) => {
    switch (action.type) {
        case actions.START_GAME:
            return updateObject(state, { gameStarted: true });
        case actions.SET_CURRENT_ROUND_STATE:
            return updateObject(state, { currentRoundState: action.payload });
        case actions.CLEAR_STATS:
            return updateObject(state, { stats: {} });
        case actions.SET_CURRENT_ROUND_INDEX:
            return updateObject(state, { currentRoundIndex: action.payload });
        case actions.SET_CURRENT_DIFFICULTY_LEVEL_INDEX:
            return updateObject(state, { currentDifficultyLevelIndex: action.payload });
        case actions.SET_INTEGRATION_PARAMS: {
            let currentState = Object.assign({}, state);
            currentState.integrationParams = action.payload;

            return updateObject(state, currentState);
        }
        case actions.SET_MODE: {
            let currentState = Object.assign({}, state);
            currentState.mode = action.payload;

            return updateObject(state, currentState);
        }
        case actions.SET_WARNING_TYPE: {
            let currentState = Object.assign({}, state);
            currentState.warningType = action.payload;

            return updateObject(state, currentState);
        }
        default:
            return state;
    }
};
