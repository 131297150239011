import React from 'react';
import { connect } from 'react-redux';
import Splash from './components/Splash';
import Rules from './components/Rules';
import Game from './components/Game';
import AppState from './types/AppState';
import IntegrationParams from './components/IntegrationParams';
import Mode from './types/Mode';
import IntegrationParamsType from './types/IntegrationParams';
import WarningMessage from './components/WarningMessage/WarningMessage';

interface IAppProps {
    gameStarted: boolean;
    mode: Mode;
    integrationParams: IntegrationParamsType;
}

function App(props: IAppProps) {
    let content;

    if (props.mode === 'game') {
        if (!props.gameStarted) {
            content = (
                <div className={props.integrationParams.darkMode ? 'app app_dark' : 'app'}>
                    <Splash />
                </div>
            );
        } else {
            content = (
                <div className={props.integrationParams.darkMode ? 'app app_dark' : 'app'}>
                    <Rules />
                    <Game />
                </div>
            );
        }
    } else {
        content = (
            <div className={props.integrationParams.darkMode ? 'app app_dark' : 'app'}>
                <WarningMessage />
            </div>
        );
    }

    return content;
}

const mapStateToProps = (store: AppState) => {
    return {
        integrationParams: store.general.integrationParams,
        mode: store.general.mode,
        gameStarted: store.general.gameStarted,
    };
};

export default connect(mapStateToProps)(IntegrationParams(App));
