import RoundStatistics from '../types/statistics/RoundStatistics';

export class StatisticsRoundHelper {
    constructor(
        private wrongAnswersCount: number = 0,
        private startTime: Date = new Date(),
        private finishTime: Date = new Date(),
        private passTime: number = 0,
        private isDone: boolean = false,
        private answersTimes: any[] = []
    ) {}

    private countPassTimeInSeconds(): void {
        this.passTime = (this.finishTime.getTime() - this.startTime.getTime()) / 1000;
    }

    public finishRound(isDone: boolean): void {
        this.finishTime = new Date();
        this.countPassTimeInSeconds();
        this.isDone = isDone;
    }

    public addWrongAnswer(): void {
        this.wrongAnswersCount++;
    }

    public addAnswerTime(): void {
        let passTime: number = (new Date().getTime() - this.startTime.getTime()) / 1000;

        if (this.answersTimes.length > 0) {
            this.answersTimes.forEach((time) => {
                passTime -= time;
            });
        }

        if (
            this.answersTimes.length + 1 <= this.wrongAnswersCount ||
            this.wrongAnswersCount === 0
        ) {
            this.answersTimes.push(Number(passTime.toFixed(3)));
        }
    }

    public getPassTime(): number {
        return this.passTime;
    }

    public getRoundStatistic(): RoundStatistics {
        return {
            wrongAnswersCount: this.wrongAnswersCount,
            answersTimes: this.answersTimes,
            startTime: this.startTime,
            finishTime: this.finishTime,
            passTime: this.passTime,
            wasDone: this.isDone,
        };
    }
}
