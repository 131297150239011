import React from 'react';
import gameService from '../services/gameService';
import Rules from './Rules';

class GameSettings extends React.Component {
    handleStart = () => {
        gameService.newGame();
    };

    render() {
        return (
            <div>
                <Rules />
                <div className="game-footer">
                    <button className="button button_l button_red" onClick={this.handleStart}>
                        Начать игру
                    </button>
                </div>
            </div>
        );
    }
}

export default GameSettings;
