import actions from './types';
import StoreAction from '../../types/StoreAction';
import RoundState from '../../types/RoundState';

export const startGame = (): StoreAction => {
    return {
        type: actions.START_GAME,
        payload: {},
    };
};

export const setCurrentRoundState = (state: RoundState): StoreAction => {
    return {
        type: actions.SET_CURRENT_ROUND_STATE,
        payload: state,
    };
};

export const clearStats = (): StoreAction => {
    return {
        type: actions.CLEAR_STATS,
        payload: {},
    };
};

export const setCurrentRoundIndex = (roundIndex: number): StoreAction => {
    return {
        type: actions.SET_CURRENT_ROUND_INDEX,
        payload: roundIndex,
    };
};

export const setCurrentDifficultyLevelIndex = (difficultyLevelIndex: number): StoreAction => {
    return {
        type: actions.SET_CURRENT_DIFFICULTY_LEVEL_INDEX,
        payload: difficultyLevelIndex,
    };
};

export const setUrlParams = (value: any): StoreAction => {
    return {
        type: actions.SET_URL_PARAMS,
        payload: value,
    };
};
