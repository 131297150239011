import { StatisticsRoundHelper } from './statisticRoundHelper';
import DifficultyLevelSettings from '../types/DifficultyLevelSettings';
import RoundStatistics from '../types/statistics/RoundStatistics';
import LevelStatictics from '../types/statistics/LevelStatistics';

export class StatisticLevelHelper {
    private levelSettings: DifficultyLevelSettings;
    private roundsStorage: StatisticsRoundHelper[];
    private isDone: boolean = false;
    private score: number = 0;

    public currentRound: StatisticsRoundHelper;

    constructor(difficultyLevelSettings: DifficultyLevelSettings) {
        this.levelSettings = difficultyLevelSettings;
        this.currentRound = new StatisticsRoundHelper();
        this.roundsStorage = new Array<StatisticsRoundHelper>();
    }

    public wrongAnswer(): void {
        this.currentRound.addWrongAnswer();
        this.currentRound.addAnswerTime();
    }

    public rightAnswer(): void {
        this.currentRound.addAnswerTime();
    }

    public nextRound(isPreviousRoundDone: boolean): void {
        this.currentRound.finishRound(isPreviousRoundDone);
        this.roundsStorage.push(this.currentRound);
        this.currentRound = new StatisticsRoundHelper();
    }

    public doneLevel(isPreviousRoundDone: boolean): void {
        this.nextRound(isPreviousRoundDone);
        this.isDone = true;
    }

    public setLevelScore(): void {
        let levelStatistic = this.getLevelStatistic();

        if (levelStatistic) {
            let levelMode = levelStatistic.levelSettings?.roundSettings.mode,
                possiblePoints: number[] = [];

            if (levelMode === 'single') {
                possiblePoints = [3, 2, 1];
            } else {
                possiblePoints = [6, 4, 2];
            }

            let levelScore = 0;
            levelStatistic.rounds.forEach((round) => {
                if (round.wasDone) {
                    levelScore += possiblePoints[round.wrongAnswersCount];
                }
            });

            this.score = levelScore;
        }
    }

    public abort(): void {
        this.currentRound.finishRound(false);
        this.roundsStorage.push(this.currentRound);
    }

    public getLevelStatistic(): LevelStatictics {
        const levelStatistic = new Array<RoundStatistics>();
        let passTime = 0;
        this.roundsStorage.forEach((round) => {
            levelStatistic.push(round.getRoundStatistic());
            passTime += round.getPassTime();
        });
        return {
            levelSettings: this.levelSettings,
            levelPassTime: passTime,
            rounds: levelStatistic,
            score: this.score,
        };
    }
}
