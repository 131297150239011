const actions = {
    START_GAME: 'START_GAME',
    SET_CURRENT_ROUND_STATE: 'SET_CURRENT_ROUND_STATE',
    CLEAR_STATS: 'CLEAR_STATS',
    SET_CURRENT_ROUND_INDEX: 'SET_CURRENT_ROUND_INDEX',
    SET_CURRENT_DIFFICULTY_LEVEL_INDEX: 'SET_CURRENT_DIFFICULTY_LEVEL_INDEX',
    SET_URL_PARAMS: 'SET_URL_PARAMS',
    SET_INTEGRATION_PARAMS: 'SET_INTEGRATION_PARAMS',
    SET_MODE: 'SET_MODE',
    SET_WARNING_TYPE: 'SET_WARNING_TYPE',
};

export default actions;
