import storeService from './storeService';
import pjson from '../../package.json';

class GraylogService {
    sendMessage(params: {
        short_message: string;
        full_message: string;
        _body?: string;
        _body_result?: any;
    }): Promise<Response> {
        const state = storeService.getStore().getState().general;
        const { token, unti_id, activity_uuid } = state.integrationParams;

        return fetch('https://graylog.rnd.2035.university/gelf', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                version: pjson.version,
                host: window.location.href,
                _user_token: token,
                _unti_id: unti_id,
                _activity_uuid: activity_uuid,
                ...params,
            }),
        });
    }
}

const graylogService = new GraylogService();

export default graylogService;
