import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, Store } from 'redux';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import { composeWithDevTools } from 'redux-devtools-extension';
import ru_Ru from 'antd/lib/locale-provider/ru_RU';

import reducers from './store/reducers';
import storeService from './services/storeService';
import dispatcherService from './services/dispatcherService';
import App from './App';
import pjson from '../package.json';

import './css/index.scss';


const store: Store = createStore(reducers, {}, composeWithDevTools());
storeService.setStore(store);

const root = document.getElementById('webbot');

dispatcherService.setParams(root);

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider locale={ru_Ru}>
            <App />
        </ConfigProvider>
    </Provider>,
    root
);

console.log(
    `%c Adaptive Logic version: ${pjson.version} `,
    'background-color: #ffdd2d; color: #333;'
);
