import React, { Component } from 'react';

class Rules extends Component {
    render() {
        return (
            <div className="rules">
                <div className="rules-header">
                    <p className="rules-header__title">Правила:</p>
                </div>
                <div className="rules-items">
                    <div className="rules-item">
                        <p>В этой игре вы увидите несколько строк с разными фигурами.</p>
                        <p>В каждой строке есть несколько фигур, расположенных по правилу:</p>
                        <ul>
                            <li>
                                <b>если</b> предыдущая фигура обладает свойством «а», <b>то</b>{' '}
                                следующая фигура обладает свойством «б».
                            </li>
                        </ul>
                    </div>
                    <div className="rules-item">
                        <p>Например:</p>
                        <ul>
                            <li>
                                <b>если</b> большой, <b>то</b> квадрат;
                            </li>
                            <li>
                                <b>если</b> маленький, <b>то</b> большой;
                            </li>
                            <li>
                                <b>если</b> белый, <b>то</b> круг.
                            </li>
                        </ul>
                    </div>
                    <div className="rules-item">
                        <p>
                            Обратите внимание, что в правиле не могут встречаться одинаковые
                            свойства. Например, игра не может задать правило:
                        </p>
                        <ul>
                            <li>
                                <b>если</b> большой, <b>то</b> большой.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default Rules;
