import React, { Component, ChangeEvent } from 'react';
import Condition from '../types/Condition';
import { SelectorType } from '../types/SelectorType';

interface IGameSelectProps {
    selectedElement: Condition;
    type: SelectorType;
    onElementSelected: (event: ChangeEvent<HTMLSelectElement>) => void;
}

class GameSelect extends Component<IGameSelectProps> {
    render() {
        const selectElem = (
            <select
                className="form__select"
                name={this.props.type.toString()}
                onChange={this.props.onElementSelected}
            >
                <option value="big">большой</option>
                <option value="small">маленький</option>
                <option value="black">чёрный</option>
                <option value="white">белый</option>
                <option value="square">квадрат</option>
                <option value="round">круг</option>
            </select>
        );

        return <div>{selectElem}</div>;
    }
}

export default GameSelect;
