import React, { Component } from 'react';
import Element from '../types/Element';
import SequenceElement from './SequenceElement';

interface ISequencceProps {
    sequence: Element[];
}

class Seuqence extends Component<ISequencceProps> {
    render() {
        let elements = [];
        for (let i = 0; i < this.props.sequence.length; i++) {
            elements.push(
                <SequenceElement key={'el' + i} element={this.props.sequence[i]}></SequenceElement>
            );
        }

        return <div>{elements}</div>;
    }
}

export default Seuqence;
